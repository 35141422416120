/* eslint-disable react/prefer-stateless-function */
import { DefaultAboutPageProps } from '@/common/props/page.props';
import { IQueryPageProps } from '@/common/types/page.types';
import ContainerStyles from '@/components/container/Container.styles';
import PAGE_CONTENT_CONTAINER from '@/components/container/PageContentContainer.component';
import HERO from '@/components/hero/Hero.component';
import ARTICLE_PREVIEW from '@/components/list/ArticlePreview.component';
import SEO from '@/components/Seo';
import { Divider } from '@chakra-ui/react';
import { graphql, StaticQueryDocument } from 'gatsby';
import { get } from 'lodash';
import React from 'react';

function AboutIndex({
  data,
  location,
}: IQueryPageProps<Queries.AboutQuery>): JSX.Element {
  const posts = get(data, 'allContentfulBlogPost.nodes');
  const [page] = get(data, 'allContentfulPage.nodes');

  const { heroImage, title, sections } = page;
  const { seoTitle, seoDescription, seoKeywords } = DefaultAboutPageProps;

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords}
        pathname={location.pathname}
      />
      <HERO showTitle image={heroImage.gatsbyImage} title={title} />
      <ContainerStyles.PageContainer>
        {sections.map((section: Queries.ContentfulContentBlock) => (
          <React.Fragment key={section.id}>
            <PAGE_CONTENT_CONTAINER {...section} />
            <Divider
              margin={{
                base: '32px auto',
                md: '48px auto',
              }}
            />
          </React.Fragment>
        ))}
        <ARTICLE_PREVIEW posts={posts} />
      </ContainerStyles.PageContainer>
    </>
  );
}

export default AboutIndex;

export const aboutPageQuery: StaticQueryDocument = graphql`
  query About {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
        tags {
          tagText
        }
      }
    }
    allContentfulPage(
      filter: { contentful_id: { eq: "6UwPRq67R4FMDtGKm8If77" } }
    ) {
      nodes {
        title
        subTitle
        slug
        url {
          id
          url
        }
        heroImage: heroImage {
          gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1680)
        }
        sections {
          id
          title
          content {
            raw
          }
          image: image {
            gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 600)
          }
          imageAlt
          imageLeftAligned
          ctaPath
          showCta
        }
      }
    }
  }
`;
